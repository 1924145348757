import React, { Component } from 'react';
import BlogFeed from '../../widgets/blog/blogfeed';


class Blog extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <section>
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">
                            <div className="mb-8">
                                <h2 className="mt-3">Our latest articles</h2>
                                <p className="lead mb-0">Advice, research, how-tos, and insights to help you level-up your ecommerce store.</p>
                            </div>
                        </div>
                    </div>
                    {/* / .row */}
                    <BlogFeed />
                </div>
            </section>
        );
    }
}

export default Blog;