import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Features extends Component {
    render() {
        return (
            <div className="col-12 col-md-12 col-lg-8">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6 col-md-6 mb-8 mb-lg-0">
                <div className="p-3 d-flex align-items-center shadow">
                  <div className="mr-5">
                    <img className="img-fluid img-sm rounded-top" src={require(`../../assets/images/features/store-front.svg`)} alt="" />
                  </div>
                  <div>                   
                    <h5 className="mb-2">We Do the Work</h5>             
                    <p>Firesell automatically lists and manages your products on multiple marketplaces and liquidation sites.</p>
                   
                  </div>
                </div>
                <div className="p-3 d-flex align-items-center shadow mt-8">
                  <div className="mr-5">
                    <img className="img-fluid img-sm rounded-top" src={require(`../../assets/images/features/discount.svg`)} alt="" />
                  </div>
                  <div>
                    <h5 className="mb-2">Forget Sales and Discounts</h5>            
                    <p>Discounting your products eats away at revenue. Keep more of your margins by selling to a larger audience at smaller discounts.</p>                     
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-6 mb-8 mb-lg-0">
                <div className="text-center shadow p-5">
                  <div className="mb-3">
                    <img className="img-fluid img-lg rounded-top" src={require(`../../assets/images/features/rankings.svg`)} alt="" />
                  </div>
                  <div>                    
                    <h5 className="mb-2">Ultimate Visibility</h5>             
                    <p>Firesell products appear further up in marketplace search results than products sold by merchants on their own.</p>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
       
        );
    }
}

export default Features;