import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Pricingplan3 extends Component {
  render() {
    return (

      <div className="row align-items-center">
        <div className="col-12 col-lg-4 mb-8 mb-lg-0">
          {/* Card */}
          <div className="card price-card-height shadow border-0 hover-translate">
            {/* Body */}
            <div className="card-body py-11 px-6">
              {/* Features */}
              <div className="price-card-feature-height">
                <div className="d-flex align-items-start justify-content-between">
                  {/* Text */}
                  <p><b>7% fee</b> on every item sold</p>
                  {/* Check */}
                  <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                  </div>
                </div>
                <div className="d-flex align-items-start justify-content-between">
                  {/* Text */}
                  <p>Performance dashboard</p>
                  {/* Check */}
                  <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                  </div>
                </div>
              </div>
              <div className="price-card-price-height">
                {/* Badge */}
                <div className="text-center my-5"> <span className="badge shadow badge-primary">
                  <span className="h6 text-uppercase">Basic</span>
                </span>
                </div>
                {/* Price */}
                <div className="d-flex pt-3 justify-content-center">
                  {/* <span className="h2 mb-0 mt-2">$</span> */}
                  <span className="price display-4">Free</span>
                  {/* <span className="h2 align-self-end text-primary">/mo</span> */}
                </div>
                <p className="text-center mb-6 mb-md-8">Forever</p>
              </div>
              {/* Button */}
              <a id="freePlan" href="/#signup" className="btn btn-block btn-outline-primary mt-5">
                Choose Package
                    </a>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 mb-8 mb-lg-0">
          {/* Card */}
          <div className="card price-card-height bg-primary shadow border-0">
            {/* Body */}
            <div className="card-body py-11 px-6">
              {/* Features */}
              <div className="price-card-feature-height">
                <div className="d-flex align-items-start justify-content-between">
                  {/* Text */}
                  <p className="text-light"><b>3% fee</b> on every item sold</p>
                  {/* Check */}
                  <div className="ml-4"> <i className="la la-check text-light font-weight-bold" />
                  </div>
                </div>
                <div className="d-flex align-items-start justify-content-between">
                  {/* Text */}
                  <p className="text-light">Performance dashboard</p>
                  {/* Check */}
                  <div className="ml-4"> <i className="la la-check text-light font-weight-bold" />
                  </div>
                </div>
                <div className="d-flex align-items-start justify-content-between">
                  {/* Text */}
                  <p className="text-light">Dead stock detection</p>
                  {/* Check */}
                  <div className="ml-4"> <i className="la la-check text-light font-weight-bold" />
                  </div>
                </div>
                <div className="d-flex align-items-start justify-content-between">
                  {/* Text */}
                  <p className="text-light">Competitive price analysis</p>
                  {/* Check */}
                  <div className="ml-4"> <i className="la la-check text-light font-weight-bold" />
                  </div>
                </div>
              </div>
              <div className="price-card-price-height">
                {/* Badge */}
                <div className="text-center my-5"> <span className="badge shadow badge-light">
                  <span className="h6 text-uppercase">Standard</span>
                </span>
                </div>
                {/* Price */}
                <div className="d-flex justify-content-center text-white"> <span className="h2 mb-0 mt-2">$</span>
                  <span className="price display-3">299</span>
                  <span className="h2 align-self-end">/mo</span>
                </div>

                {/* Text */}
                <p className="text-center text-light mb-6 mb-md-8">Per store</p>
              </div>
              {/* Button */} <a id="standardPlan"  href="/#signup" className="btn btn-block btn-outline-light mt-5">
                Choose Package
                      </a>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4">
          {/* Card */}
          <div className="card price-card-height shadow border-0 hover-translate">
            {/* Body */}
            <div className="card-body py-11 px-6">
              <div className="price-card-feature-height">
                {/* Features */}
                <div className="d-flex align-items-start justify-content-between">
                  {/* Text */}
                  <p>Negotiated sale fee</p>
                  {/* Check */}
                  <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                  </div>
                </div>
                <div className="d-flex align-items-start justify-content-between">
                  {/* Text */}
                  <p>Custom Integration</p>
                  {/* Check */}
                  <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                  </div>
                </div>
                <div className="d-flex align-items-start justify-content-between">
                  {/* Text */}
                  <p>Dead stock detection</p>
                  {/* Check */}
                  <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                  </div>
                </div>
                <div className="d-flex align-items-start justify-content-between">
                  {/* Text */}
                  <p>Competitive price analysis</p>
                  {/* Check */}
                  <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                  </div>
                </div>
                <div className="d-flex align-items-start justify-content-between">
                  {/* Text */}
                  <p>Customer success manager</p>
                  {/* Check */}
                  <div className="ml-4"> <i className="la la-check text-primary font-weight-bold" />
                  </div>
                </div>
              </div>
              <div className="price-card-price-height">
                {/* Badge */}
                <div className="text-center my-5"> <span className="badge shadow badge-primary">
                  <span className="h6 text-uppercase">Extended</span>
                </span>
                </div>
                {/* Price */}
                <div className="d-flex justify-content-center pt-4">
                  {/* <span className="h2 mb-0 mt-2">$</span> */}
                  <span className="price display-4">Custom</span>
                  {/* <span className="h2 align-self-end text-primary">/mo</span> */}
                </div>

                {/* Text */}
                <p className="text-center text-muted">Per store</p>
              </div>
              {/* Button */} <a id="extendedPlan" href="/#signup" className="btn btn-block btn-outline-primary mt-5">
                Choose Package
                      </a>
            </div>
          </div>
        </div>
      </div>


    );
  }
}

export default Pricingplan3;