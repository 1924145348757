import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Herosection extends Component {

    render() {
        return (

            <div className="row align-items-center">

                <div className="col-12 col-xl-6 order-lg-1 mb-8 mb-lg-0">
                    {/* Heading */}
                    {/* <div className="h1 d-none d-sm-block font-weight-bold">
                        A new way to <span className="text-primary"> <br></br>liquidate inventory</span>
                    </div>
                    <div className="h1 d-block d-sm-none font-weight-bold">
                        A new way to <span className="text-primary"> <br></br>liquidate</span>
                    </div> */}
                    <div className="h1 d-none d-sm-block font-weight-bold">
                        Get rid of your <span className="text-primary"> dead stock</span>
                    </div>
                    <div className="h1 d-block d-sm-none font-weight-bold">
                        Get rid of your <span className="text-primary"> <br></br> dead stock</span>
                    </div>
                    {/* Text */}
                    <p className="lead text-muted">Boost sales of stale and slow moving inventory. Let Firesell handle the last stretch of selling out your stock.</p>
                    {/* <a
                        activeClass="active"
                        href="#signup"
                        className="btn btn-primary shadow mr-1">
                        Start selling today
                    </a>  */}
                    <a href="https://apps.shopify.com/firesell">
                        <img src={require(`../../assets/images/integrations/Shopify-App-Store-Badge-Final-Black.svg`)} width="200px" alt="..." />
                    </a>

                </div>
                <div className="col-12 col-xl-6 order-lg-2 ">
                    {/* Image */}
                    <img src={require(`../../assets/images/hero/main-photo.svg`)} className="img-fluid" alt="..." />
                </div>
            </div>

        );
    }
}

export default Herosection;