import React, { Component } from 'react';
import EarlyAdopterForm from '../form/earlyAdopterForm';

class Memberaboutus1 extends Component {
    render() {
        return (
            <section className="pb-11">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-12 col-lg-6 mb-6 mb-lg-0">
                            <div>
                                <span className="badge badge-primary-soft p-2 font-w-6">
                                    About the program
                                        </span>
                                <h2 className="mt-3 font-w-5">Let Firesell solve your inventory problems</h2>
                                <p className="lead">We want to understand your pain points when it comes to dead stock and returned inventory.</p>
                                <p className="mb-0">As an early adopter you will get early access to Firesell and an oportunity to influence the product.</p>
                            </div>
                        </div>
                        <div className="col-12 mt-5 mt-lg-0 col-lg-6 col-xl-5">
                            <div className="text-center">
                                <h3>Get Access To The Plugin</h3>
                            </div>
                            <EarlyAdopterForm />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Memberaboutus1;