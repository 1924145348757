import React, { Component } from 'react';
import Pricingplan from '../../widgets/pricingplan/pricingplan3';
import PricingplanMobile from '../../widgets/pricingplan/pricingplan2';
import AccordionDemo from '../../widgets/featuare/accordiondemo';

class Pricing extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <section id="price">
                <div className="container">
                    <div className="row align-items-end mb-8">
                        <div className="col-12 col-md-12 col-lg-5">
                            <div> <span className="h6 text-primary">
                                Price Table
                        </span>
                                <h2 className="mt-4 mb-0 font-w-5">Start your Firesale today</h2>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 ml-auto">
                            <div>
                                <p className="lead mb-0">Firesell is free to install and use. Our extended plan is best suited for merchants that sell high volume.</p>
                            </div>
                        </div>
                    </div>
                    <div className="d-block d-lg-none">
                        <PricingplanMobile />
                    </div>
                    <div className="d-none d-lg-block">
                        <Pricingplan />
                    </div>
                    <div id="FAQ" />
                </div>
                <div className="container mt-xxl">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-12 col-lg-6 col-xl-5">
                            <AccordionDemo />
                        </div>
                        <div className="col-12 col-lg-6 mt-10 mt-lg-0 mb-8 mb-lg-0">
                            <div className="row align-items-center">
                                <div className="col-6">
                                    <img src={require(`../../assets/images/early-adopters/storage5.jpg`)} className="img-fluid rounded shadow-lg" alt="..." />
                                </div>
                                <div className="col-6">
                                    <img src={require(`../../assets/images/early-adopters/shop2.jpg`)} className="img-fluid rounded shadow-lg mb-5" alt="..." />
                                    <img src={require(`../../assets/images/early-adopters/shop3.jpg`)} className="img-fluid rounded shadow-lg" alt="..." />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default Pricing;