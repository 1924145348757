import React, { Component } from 'react';
import md from './md/blog1.txt';
import marked from "marked";

class BlogPost1 extends Component {

    constructor(props) {
        super(props);
        this.state = { markdown: null };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.setState({
            markdown: marked(md)
        })
    }

    render() {
        const { markdown } = this.state;

        return (
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">
                            <div dangerouslySetInnerHTML={{ __html: markdown }}>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default BlogPost1;