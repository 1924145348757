/**
 *  Main Menu Json
 */
export default [
   {
      "menu_title": "How It Works",
      "path": "/#howItWorks",
      "icon": "home"
   },
   {
      "menu_title": "Solution",
      "path": "/#solution",
      "icon": "party_mode",
   },
   {
      "menu_title": "Pricing",
      "path": "/pricing",
      "icon": "party_mode",
   },
   {
      "menu_title": "Contact",
      "path": "/contact-us",
      "icon": "home"
   }
]
