import React, { Component } from 'react';
import Memberaboutus1 from '../../../widgets/aboutus/aboutus1';
import AccordionDemo from '../../../widgets/featuare/accordiondemo';

import Pageheading from '../../../widgets/Pageheading';

class Aboutus2 extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                <section className="position-relative" id="earlyAdopters">
                    <Pageheading foldername={"Company"} title={"Early Adopters Program"} />

                </section>
                {/*hero section end*/}
                {/*body content start*/}
                <div className="page-content">
                    {/*about start*/}
                    <Memberaboutus1 />
                    {/*about end*/}
                    <section>
                        <div className="container">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-12 col-lg-6 col-xl-5">
                                    <AccordionDemo />
                                </div>
                                <div className="col-12 col-lg-6 mt-10 mt-lg-0 mb-8 mb-lg-0">
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <img src={require(`../../../assets/images/early-adopters/storage5.jpg`)} className="img-fluid rounded shadow-lg" alt="..." />
                                        </div>
                                        <div className="col-6">
                                            <img src={require(`../../../assets/images/early-adopters/shop2.jpg`)} className="img-fluid rounded shadow-lg mb-5" alt="..." />
                                            <img src={require(`../../../assets/images/early-adopters/shop3.jpg`)} className="img-fluid rounded shadow-lg" alt="..." />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/*body content end*/}
            </div>

        );
    }
}

export default Aboutus2;