import React, { Component } from 'react';
import { customerService } from '../../services/customer-service';

class ContactForm1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            success: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() { }
    componentWillUnmount() { }

    handleSubmit = (e) => {
        event.preventDefault();
        this.setState({ loading: true });

        const customer = {
            'firstName': e.target[0].value,
            'lastName': e.target[1].value,
            'email': e.target[2].value,
            'phoneNumber': e.target[3].value,
            'desiredPlan': e.target[4].value,
            'subject': e.target[5].value,
            'message': e.target[6].value,
        }

        customerService.addNewContactEnquiry(customer)
            .then(() => {
                this.setState({ success: true });
            }).catch(() => {
                this.setState({ success: false });
            }).finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <div className="row justify-content-center text-center">
                {!this.state.success ?
                    <div className="col-12 col-lg-10">
                        <form id="contact-form" className="row" onSubmit={this.handleSubmit}>
                            <div className="messages" />
                            <div className="form-group col-md-6">
                                <input id="form_name" type="text" name="name" className="form-control" placeholder="First Name" required="required" data-error="Name is required." />
                                <div className="help-block with-errors" />
                            </div>
                            <div className="form-group col-md-6">
                                <input id="form_name1" type="text" name="name" className="form-control" placeholder="Last Name" required="required" data-error="Name is required." />
                                <div className="help-block with-errors" />
                            </div>
                            <div className="form-group col-md-12">
                                <input id="form_email" type="email" name="email" className="form-control" placeholder="Email" required="required" data-error="Valid email is required." />
                                <div className="help-block with-errors" />
                            </div>
                            <div className="form-group col-md-12">
                                <input id="form_phone" type="tel" name="phone" className="form-control" placeholder="Phone" required="required" data-error="Phone is required" />
                                <div className="help-block with-errors" />
                            </div>
                            <div className="form-group col-md-6">
                                <select className="form-control" required>
                                    <option>- What plan are you interested in?</option>
                                    <option>Basic (Free)</option>
                                    <option>Premium ($299/mo)</option>
                                    <option>Extended (Custom)</option>
                                </select>
                            </div>
                            <div className="form-group col-md-6">
                                <input id="form_subject" type="text" name="subject" className="form-control" placeholder="Subject" required="required" data-error="Subject is required" />
                                <div className="help-block with-errors" />
                            </div>
                            <div className="form-group col-md-12">
                                <textarea id="form_message" name="message" className="form-control" placeholder="Message" rows={4} required="required" data-error="Please,leave us a message." defaultValue={""} />
                                <div className="help-block with-errors" />
                            </div>
                            <div className="col-md-12 text-center mt-4">

                                <button type="submit" className="btn btn-primary">
                                    {this.state.loading ?
                                        <div className="spinner-border text-light"></div>
                                        :
                                        <div>
                                            Reach out
                                        </div>
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                    :
                    <div className="text-primary h3">
                        Thank you! We have received your message.
                    </div>
                }
            </div>

        );
    }
}

export default ContactForm1;