import React, { Component } from 'react';
import { customerService } from '../../services/customer-service';

class EarlyAdopterForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            success: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() { }
    componentWillUnmount() { }

    handleSubmit = (e) => {
        event.preventDefault();
        this.setState({ loading: true });

        const customer = {
            'firstName': e.target[0].value,
            'email': e.target[1].value,
        }

        customerService.addNewEarlyAdopter(customer)
            .then(() => {
                this.setState({ success: true });
            }).catch(() => {
                this.setState({ success: false });
            }).finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <div className="shadow rounded p-5">
                {/* <img className="img-fluid mb-8" src={require(`../../assets/images/about/04.png`)} alt="Image" /> */}
                <form className="row" id="early-adopter-form" onSubmit={this.handleSubmit}>
                    <div className="messages" />
                    <div className="form-group col-12">
                        <label className="text-muted" htmlFor="InputName1">First Name</label>
                        <input type="text" className="form-control input-1" id="InputName1" placeholder="First Name" required="required" data-error="Firstname is required." />
                        <div className="help-block with-errors" />
                    </div>
                    {/* <div className="form-group col-sm-6">
                        <label className="text-muted" htmlFor="InputName2">Last Name</label>
                        <input type="text" className="form-control input-1" id="InputName2" placeholder="Last Name" required="required" data-error="Lastname is required." />
                        <div className="help-block with-errors" />
                    </div> */}
                    <div className="form-group col-12">
                        <label className="text-muted" htmlFor="InputEmail1">Email address</label>
                        <input type="email" className="form-control input-1" id="InputEmail1" placeholder="Enter email" required="required" data-error="Valid email is required." />
                        <div className="help-block with-errors" />
                    </div>
                    {/* <div className="form-group col-12">
                        <label className="text-muted" htmlFor="StoreWebsite">Store URL</label>
                        <input type="text" className="form-control input-1" id="InputName1" placeholder="Your e-commerce store URL" required="required" data-error="URL is mandatory." />
                        <div className="help-block with-errors" />
                    </div> */}
                    {/* <div className="form-group col-12">
                        <label className="text-muted" htmlFor="StoreWebsite">What plan are you interested in?</label>
                        <select className="form-control" required="required">
                            <option>Select a plan</option>
                            <option>Basic (Free)</option>
                            <option>Standard ($299/mo)</option>
                            <option>Extended (Custom)</option>
                        </select>
                        {/* <div className="help-block with-errors" /> */}
                    {/* </div> */}
                    <div className="col-12 mt-5">
                        {this.state.success ?
                            <div className="text-primary h3">
                                Thank you! Please check your email.
                            </div>
                            :
                            <div>
                                <button type="submit" className="btn btn-primary mx-auto d-block">
                                    <div>
                                        {this.state.loading ?
                                            <div className="spinner-border text-light"></div>
                                            :
                                            <div>
                                                Sign Up
                                        </div>
                                        }
                                    </div>
                                </button>
                                <div class="d-flex mt-sm">
                                    <hr class="my-auto flex-grow-1" />
                                    <div class="px-4">Or</div>
                                    <hr class="my-auto flex-grow-1" />
                                </div>
                                <div className="text-center mt-sm">
                                    <a href="https://apps.shopify.com/firesell">
                                        <img src={require(`../../assets/images/integrations/Shopify-App-Store-Badge-Final-Black.svg`)} width="210px" alt="..." />
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                </form>
            </div>
        );
    }
}

export default EarlyAdopterForm;