import React, { Component } from 'react';
import { customerService } from '../../services/customer-service';

class Newsletter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            success: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() { }
    componentWillUnmount() { }

    handleSubmit = (e) => {
        event.preventDefault();
        this.setState({ loading: true });

        const customer = {
            'email': e.target[0].value,
        }

        customerService.addNewEmailSubscription(customer)
            .then(() => {
                this.setState({ success: true });
            }).catch(() => {
                this.setState({ success: false });
            }).finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <div className="col-12 col-lg-5 col-xl-6 mr-auto mb-6 mb-lg-0">
                <div className="subscribe-form bg-warning-soft p-5 rounded">
                    <h5 className="mb-4 text-white">Newsletter</h5>
                    <h6 className="text-light">Subscribe to our newsletter</h6>
                    <form id="mc-form" className="group" onSubmit={this.handleSubmit}>
                        <input type="email" name="EMAIL" className="email form-control" id="email" placeholder="Email Address" required style={{ height: '60px' }} />

                        {this.state.success ?
                            <div className="text-light mt-3 h4">
                                You have been subscribed!
                            </div>
                            :
                            <div>
                                <button type="submit" className="btn btn-outline-light btn-block mt-3 mb-2">
                                    <div>
                                        {this.state.loading ?
                                            <div className="spinner-border text-secondary"></div>
                                            :
                                            <div>
                                                Submit
                                        </div>

                                        }
                                    </div>
                                </button>
                                <small className="text-light">Stay up to date on product trends and fads.</small>
                            </div>
                        }
                        {/* <input className="btn btn-outline-light btn-block mt-3 mb-2" type="submit" name="subscribe" defaultValue="Subscribe" /> */}
                    </form>
                </div>
            </div>
        );
    }
}

export default Newsletter;