import React, { Component } from 'react';
import Pricingplan from '../../widgets/pricingplan/pricingplan3';
import PricingplanMobile from '../../widgets/pricingplan/pricingplan2';
import Clientlogo from '../../widgets/common/clientlogo';
import About from '../../widgets/index/about2';
import Features from '../../widgets/features/features'
import { Link } from 'react-router-dom';
import Herosection from '../../widgets/herosection/herosection';
import EarlyAdopterForm from '../../widgets/form/earlyAdopterForm'
import ReactGA from 'react-ga';

class Index extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        ReactGA.initialize('UA-153759131-2');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
    render() {
        return (
            <div>
                <section id="home">
                    <div className="container">
                        <Herosection />
                    </div>
                </section>
                <section className="custom-py-2 position-relative bg-dark" data-bg-img={require(`../../assets/images/bg/02.png`)}>
                    <div>
                        <div className="container">
                            <div className="d-md-none text-center">
                                <div className="row align-items-center">
                                    <div className="col-12 mb-5">
                                        <div className="h3 text-white font-w-2 mb-0 ">Firesell gets rid of your stock by working with large marketplaces and liquidators to get the <span className="font-weight-bold">absolute best price</span> for your products.</div>
                                    </div>
                                    <div className="col-12 mt-5">
                                        <img src={require(`../../assets/images/problem/marketplaces.svg`)} alt="Image" width="90%" className="mx-auto d-block rounded shadow-lg" />
                                    </div>
                                </div>
                            </div>
                            <div className="d-none d-md-block">
                                <div className="row align-items-center">
                                    <div className="col-6 mb-lg-0">
                                        <img src={require(`../../assets/images/problem/marketplaces.svg`)} alt="Image" width="80%" className="mx-auto d-block rounded shadow-lg" />
                                    </div>
                                    <div className="col-6 mb-lg-0">
                                        <div className="h2 text-white font-w-2 mb-0 text-left">Firesell gets rid of your stock by working with large marketplaces and liquidators to get the <span className="font-weight-bold">absolute best price</span> for your products.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="shape-1" style={{ height: '100px', overflow: 'hidden' }}>
                            <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                                <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{ stroke: 'none', fill: '#fff' }} />
                            </svg>
                        </div>
                        <div className="shape-1 bottom" style={{ height: '100px', overflow: 'hidden' }}>
                            <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                                <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{ stroke: 'none', fill: '#fff' }} />
                            </svg>
                        </div>
                    </div>
                </section>


                <section id="howItWorks">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-12 col-lg-4">
                                <div>

                                    <div className="d-none d-md-block">
                                        <div className="mt-3 text-center text-md-left font-w-5 h1 mb-0">How it works</div>
                                    </div>
                                    <div className="d-md-none">
                                        <div className="mt-3 text-center text-md-left font-w-5 display-4 mb-0">How it works</div>
                                    </div>
                                    <p className="lead mt-3 mb-0">We sell your unwanted stock under the official Firesell seller account
                                    on sites like Wish, Etsy, Overstock and many more. All you have to worry about is fulfilling the orders that are sent directly to your ecommerce store.</p>
                                    <Link to="/pricing" className="btn btn-primary mt-5 mb-5">
                                        View Pricing
                                    </Link>
                                    <a href="/pricing#FAQ" className="btn btn-outline-primary ml-2 mt-5 mb-5">
                                        FAQs
                                    </a>
                                </div>
                            </div>
                            <Features />
                        </div>
                    </div>
                </section>
                <section id="solution">
                    <div className="d-none d-md-block">
                        <div className="font-w-5 h1 text-center mb-0 ">Our solution</div>
                    </div>
                    <div className="d-md-none">
                        <div className="font-w-5 display-4 text-center mb-0 ">Our solution</div>
                    </div>
                    <div className="d-md-none">
                        <div className="col-12 mt-50 mb-5">
                            <div>
                                <h3 className="mt-3 text-center font-w-5">Select the products you want to get rid of</h3>
                            </div>
                        </div>
                        <div className="col-12 mt-5 order-lg-1">
                            <img src={require(`../../assets/images/solution/Screen-1-mobile.svg`)} alt="Image" width="100%" className="mx-auto d-block rounded shadow-lg" />
                        </div>
                        <div className="col-12 mt-xl mb-5">
                            <div>
                                <h3 className="mt-3 text-center font-w-5">Set the lowest price you'd sell them at</h3>
                            </div>
                        </div>
                        <div className="col-12 order-lg-1 mt-5">
                            <img src={require(`../../assets/images/solution/Screen-2-mobile.svg`)} alt="Image" width="100%" className="mx-auto d-block rounded shadow-lg" />
                        </div>
                        <div className=" mt-xl text-center mb-5">
                            <div>
                                <h3 className="mt-3 font-w-5">Flip the switch and Firesell goes to work selling your products for you</h3>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 order-lg-1 mt-5">
                            <img src={require(`../../assets/images/solution/Screen-3-mobile.svg`)} alt="Image" width="100%" className="mx-auto d-block rounded shadow-lg" />
                        </div>
                        <div className="mt-xl mb-5 ">
                            <div>
                                <h3 className="mt-3 text-center font-w-5">You don't even have to leave your shop to fulfill orders</h3>
                            </div>
                        </div>
                        <div className="col-12 order-lg-1 mt-5">
                            <img src={require(`../../assets/images/solution/Screen-4-mobile.svg`)} alt="Image" width="100%" className="mx-auto d-block rounded shadow-lg" />
                        </div>
                    </div>
                    <div className="d-none d-md-block">
                        <div className="col-12 mt-lg mb-5">
                            <div className="text-center mx-auto">
                                <h3 className="mt-3 font-w-5">Select the products you want to get rid of</h3>
                            </div>
                        </div>
                        <div className="col-12 mt-5 order-lg-1">
                            <img src={require(`../../assets/images/solution/Screen-1.svg`)} alt="Image" width="60%" className="mx-auto d-block rounded shadow-lg" />
                        </div>
                        <div className="col-12 mt-xl mb-5">
                            <div>
                                <h3 className="mt-3 text-center font-w-5">Set the lowest price you'd sell them at</h3>
                            </div>
                        </div>
                        <div className="col-12 order-lg-1 mt-5">
                            <img src={require(`../../assets/images/solution/Screen-2-mobile.svg`)} alt="Image" width="61%" className="mx-auto d-block rounded shadow-lg" />
                        </div>
                        <div className="col-12 mt-xl text-center mb-5">
                            <div>
                                <h3 className="mt-3 ml-5 font-w-5">Flip the switch and Firesell goes to work selling your products for you</h3>
                            </div>
                        </div>
                        <div className="col-12 order-lg-1 mt-5">
                            <img src={require(`../../assets/images/solution/Screen-3-mobile.svg`)} alt="Image" width="62%" className="mx-auto d-block rounded shadow-lg" />
                        </div>
                        <div className="col-12 mt-xl mb-5">
                            <div>
                                <h3 className="mt-3 text-center font-w-5">Firesell sends new orders directly to your online store</h3>
                            </div>
                        </div>
                        <div className="col-12 order-lg-1 mt-5">
                            <img src={require(`../../assets/images/solution/Screen-4-mobile.svg`)} alt="Image" width="61%" className="mx-auto d-block rounded shadow-lg" />
                        </div>
                    </div>
                    <div className="row mt-xxl">
                        <div className="mx-auto text-center p-5">
                            <h3>
                                We integrate with the most popular ecommerce platforms
                            </h3>
                        </div>
                        <div className="col-12 mt-6">
                            <Clientlogo logoitems={6} />
                        </div>
                    </div>
                    <div id="signup" />
                </section>
                <section >
                    <div className="container">
                        <div>
                            <div className="h2 text-center mb-4">
                                Get started for free
                            </div>
                            <div className="mx-auto col-12 col-lg-8 col-xl-8">
                                <EarlyAdopterForm />                                
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        );
    }
}

export default Index;