import React, { Component, Fragment } from 'react';
import { HashRouter, Switch, Route, withRouter } from 'react-router-dom';
import Header from './layout/header/header';
import Footer from './layout/footer/footer';
import Index from './pages/Index/index1';

import './App.css';
import './Vendor.js';

import Blog from './pages/blog/index';
import BlogPost1 from './pages/blog-posts/blog1';
import BlogPost2 from './pages/blog-posts/blog2';
import Pricing from './pages/pricing/index';
import Aboutus2 from './pages/company/aboutus2/index';
import Contact1 from './pages/contacts/contact1/index';
import Maintenance from './pages/utilities/maintenance';
import PageNotFound from './pages/utilities/404';
import PrivacyPolicy from './pages/utilities/privacypolicy/index';
import TermCondition from './pages/utilities/termcondition/index';
import { connect } from 'react-redux';

class App extends React.Component {
  constructor(props) {
    super(props)
  }
  getUrl(pathname) {
    let pathArray = pathname.split('/');
    return `/${pathArray[1]}` === '/coming-soon' ? true : `/${pathArray[1]}` === '/maintenance' ? true : `/${pathArray[1]}` === '/page-not-found' ? true : false;
  }
  setHeader(pathname) {
    let pathArray = pathname.split('/');
    return `/${pathArray[1]}` === '/index2' ? true : `/${pathArray[1]}` === '/index3' ? true : `/${pathArray[1]}` === '/index4' ? true : `/${pathArray[1]}` === '/index5' ? true : `/${pathArray[1]}` === '/index9' ? true : false;
  }

  render() {
    const { location } = this.props;
    return (
      <Fragment>
        {
          this.getUrl(location.pathname) ?
            <Switch>
              <Route path="/coming-soon" component={ComingSoon} />
              <Route path="/maintenance" component={Maintenance} />
              <Route path="/page-not-found" component={PageNotFound} />
            </Switch>
            :
            <div className="page-wrapper">
              {this.setHeader(location.pathname) ? null : <Header />}
              <Switch>
                <Route exact path="/" component={Index} />

                <Route path="/early-adopter" component={Aboutus2} />
                <Route path="/blog" component={Blog} />
                <Route path="/contact-us" component={Contact1} />
                <Route path="/pricing" component={Pricing} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/term-condition" component={TermCondition} />
                <Route path="/how-multi-channel-can-grow-your-business" component={BlogPost1} />
                <Route path="/best-tactics-for-liquidating-deadstock" component={BlogPost2} />
                

              </Switch>
              <Footer />
              {/* <Scrolltop /> */}
            </div>
        }
      </Fragment>
    );
  }
}

const AppMapStateToProps = state => {
  return {
    products: state.data.products
  };
};

const AppMapDispatchToProps = dispatch => {
  return {
    getProducts: () => {
    }
  };
};


export default connect(AppMapStateToProps, AppMapDispatchToProps)(withRouter(App))

