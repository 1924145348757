import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Clientlogo from '../../widgets/common/clientlogo';

class About2 extends Component {

    constructor() {
        super();
        this.state = {
            earlyAdopterText: 'Join our early adopters program!',
            isHover: false
        }

    }

    onMouseover(e) {
        this.setState({ earlyAdopterText: 'Join' })
        this.setState({ isHover: true })
    }

    onMouseout(e) {
        this.setState({ earlyAdopterText: 'Join our early adopters program!' })
        this.setState({ isHover: false })
    }

    render() {
        return (
            <section>
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-12 mt-10 text-left col-lg-6 mb-5 mb-lg-0">
                            <div>
                                <h2 className="mt-3 font-w-5">Dead inventory is expensive</h2>
                                <p className="lead">The expenses associated with storing a product add up over time. This includes warehouse, insurance, utilities, and more.</p>
                            </div>
                        </div>
                        <div className="col-12 mt-10 mt-5 mt-lg-0 col-lg-6 col-xl-5">
                            <img src={require(`../../assets/images/about/vault.svg`)} alt="Image" className="width-vault-image img-fluid" />
                        </div>
                    </div>
                    <div className="row mt-xl align-items-center justify-content-between">
                        <div className="col-12 mt-10 mt-5 mt-lg-0 col-lg-6 col-xl-5">
                            <img src={require(`../../assets/images/about/multi-channel.svg`)} alt="Image" className="img-fluid" />
                        </div>
                        <div className="col-12 mt-10 text-left col-lg-6 mb-6 mb-lg-0">
                            <div>
                                <h2 className="mt-3 font-w-5">Selling multi-channel is tedious</h2>
                                <p className="lead">Signing-up for multiple seller accounts and running sales and specials to get rid of stock is time consuming.</p>
                            </div>
                        </div>
                    </div>
                    <div className="mx-auto mt-xxl h2 font-w-5">
                        On top of it all, building the reputation of a <span className="h2 font-weight-bold">seller</span> is hard.
                    </div>
                    <div className="row mt-lg">
                        <div className="col-12 col-xl-6 order-lg-2 ">
                            <img src={require(`../../assets/images/about/profile.svg`)} alt="Image" className="img-fluid" />
                        </div>
                        <div className="col-12 mt-5 col-xl-6 order-lg-2 ">
                            <p className="lead text-left medium-size-font">
                                Brand new seller accounts on Etsy, Wish and Amazon sell on average {' '}
                                <span className="h3 white font-weight-bold">
                                    95% less
                                </span>
                                {' '} than accounts with over a thousand reviews and ratings.
                            </p>
                        </div>
                        {/* <div className="col-12 mt-6">
                            <Clientlogo logoitems={6} />
                        </div> */}
                    </div>
                    {/* <div className="row mt-10">
                        <div className="mx-auto p-5">
                            <h5>
                                We integrate with the most popular e-commerce platforms
                            </h5>
                        </div>
                        <div className="col-12 mt-6">
                            <Clientlogo logoitems={6} />
                        </div>
                    </div> */}
                </div>
            </section >
        );
    }
}

export default About2;