import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class BlogFeed extends Component {
    render() {
        return (
            <div className="row justify-content-md-center">
                <div className="col-12 col-lg-4 mb-6 mb-lg-0">
                    {/* Blog Card */}
                    <div className="card bg-light">
                        <div className="position-absolute bg-white shadow-primary text-center p-2 rounded ml-3 mt-3">21
                  <br />Oct</div>
                        <img className="card-img-top shadow rounded" height="300px" src={'https://cdn-images-1.medium.com/max/1600/0*bZq4qRZd7P7N2fgH'} alt="Image" />
                        <div className="card-body pt-5"> <Link className="d-inline-block text-muted mb-2" to="/">3 min read</Link>
                            <h2 className="h5 font-weight-medium">
                                <Link className="link-title" to="/how-multi-channel-can-grow-your-business">How Multi-Channel Can Grow Your Business</Link>
                            </h2>
                            <p>Optimize your online store by using Multi-channel to get rid of dead or excess inventory.</p>
                        </div>
                        <div className="card-footer bg-transparent border-0 pt-0">
                            {/* <ul className="list-inline mb-0">
                                <li className="list-inline-item pr-4"> <Link to="/" className="text-muted"><i className="ti-comments mr-1 text-primary" /> 131</Link>
                                </li>
                                <li className="list-inline-item pr-4"> <Link to="/" className="text-muted"><i className="ti-eye mr-1 text-primary" /> 255</Link>
                                </li>
                                <li className="list-inline-item"> <Link to="/" className="text-muted"><i className="ti-comments mr-1 text-primary" /> 14</Link>
                                </li>
                            </ul> */}
                        </div>
                        <div />
                    </div>
                    {/* End Blog Card */}
                </div>
                <div className="col-12 col-lg-4 mb-6 mb-lg-0">
                    {/* Blog Card */}
                    <div className="card bg-light">
                        <div className="position-absolute bg-white shadow-primary text-center p-2 rounded ml-3 mt-3">10
                  <br />Oct</div>
                        <img className="card-img-top shadow rounded" height="300px" src={'https://cdn-images-1.medium.com/max/1600/0*BLN3NwSjRCLdhwKG'} alt="Image" />
                        <div className="card-body pt-5"> <Link className="d-inline-block text-muted mb-2" to="/">4 min read</Link>
                            <h2 className="h5 font-weight-medium">
                                <Link className="link-title" to="/best-tactics-for-liquidating-deadstock">Best Tactics for Liquidating Deadstock</Link>
                            </h2>
                            <p>Dead stock has long been thought as the cost of doing business. It doesn't have to be.</p>
                        </div>
                        <div className="card-footer bg-transparent border-0 pt-0">
                            {/* <ul className="list-inline mb-0">
                                <li className="list-inline-item pr-4"> <Link to="/" className="text-muted"><i className="ti-comments mr-1 text-primary" /> 131</Link>
                                </li>
                                <li className="list-inline-item pr-4"> <Link to="/" className="text-muted"><i className="ti-eye mr-1 text-primary" /> 255</Link>
                                </li>
                                <li className="list-inline-item"> <Link to="/" className="text-muted"><i className="ti-comments mr-1 text-primary" /> 14</Link>
                                </li>
                            </ul> */}
                        </div>
                        <div />
                    </div>
                    {/* End Blog Card */}
                </div>
            </div>

        );
    }
}

export default BlogFeed;