import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
window.fn = OwlCarousel;

class Clientlogo extends Component {
    render() {
        return (
            <div className="container px-custom">
                <div className="row">
                    <div className="w-50 py-4 py-lg-0 col-md-3">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/integrations/magento.png`)} alt="" />
                        </div>
                    </div>
                    <div className="w-50 py-4 py-lg-0  col-md-3">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/integrations/shopify.png`)} alt="" />
                        </div>
                    </div>
                    <div className="w-50 py-4 py-lg-0  col-md-3">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/integrations/bigcommerce.png`)} alt="" />
                        </div>
                    </div>
                    <div className="w-50 py-4 py-lg-0  col-md-3">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/integrations/woo.png`)} alt="" />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Clientlogo;