import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Newsletter from '../../widgets/form/newsletter';

class Footer extends Component {
    render() {
        return (
            <footer className="py-11 bg-primary position-relative" data-bg-img="assets/images/bg/03.png">
                <div className="shape-1" style={{ height: '150px', overflow: 'hidden' }}>
                    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                        <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{ stroke: 'none', fill: '#fff' }} />
                    </svg>
                </div>
                <Container className=" mt-11">
                    <Row>
                        {/* <div className="col-12 col-lg-5 col-xl-6 mr-auto mb-6 mb-lg-0">
                            <div className="subscribe-form bg-warning-soft p-5 rounded">
                                <h5 className="mb-4 text-white">Newsletter</h5>
                                <h6 className="text-light">Subscribe to our newsletter</h6>
                                <form id="mc-form" className="group">
                                    <input type="email" name="EMAIL" className="email form-control" id="mc-email" placeholder="Email Address" required style={{ height: '60px' }} />
                                    <input className="btn btn-outline-light btn-block mt-3 mb-2" type="submit" name="subscribe" defaultValue="Subscribe" />
                                </form> <small className="text-light">Stay up to date on product trends and fads.</small>
                            </div>
                        </div> */}
                        <Newsletter />
                        <div className="col-12 col-lg-6 pl-lg-10 col-xl-6">
                            <Row>
                                <Col className="col-12 col-sm-6 navbar-dark">
                                    <h5 className="mb-4 text-white">Pages</h5>
                                    <ul className="navbar-nav list-unstyled mb-0">
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="/">Home</Link>
                                        </li>
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="/pricing">Pricing</Link>
                                        </li>
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="/blog">Blog</Link>
                                        </li>
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="/early-adopter">Early Adopters Program</Link>
                                        </li>

                                    </ul>
                                </Col>
                                <Col className="col-12 pl-lg-6 col-sm-6 mt-6 mt-sm-0 navbar-dark">
                                    <h5 className="mb-4 text-white">Legal</h5>
                                    <ul className="navbar-nav list-unstyled mb-0">
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="/term-condition">Terms of Service</Link>
                                        </li>
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="/privacy-policy">Privacy Policy</Link>
                                        </li>
                                        <li className="mb-3 nav-item"><Link className="nav-link" to="/contact-us">Contact Us</Link>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                    <Row className="mb-0 mt-8">
                        <Col>
                            <Link className="footer-logo text-white h2 mb-0" to="/">
                                <img src={require(`../../assets/images/SVG_LOGO_WHITE.svg`)} width="150px" className="img-fluid mx-auto d-block" alt="..." />
                            </Link>
                        </Col>
                    </Row>
                    <Row className="text-white text-center">
                        <Col>
                            <hr className="mb-2" />Copyright 2020 Firesell Inc. | All Rights Reserved
                        </Col>
                    </Row>

                </Container>
            </footer>
        );
    }
}

export default Footer;