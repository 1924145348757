import * as firebase from "firebase/app";
import "firebase/firestore";
import axios from 'axios';

const firebaseConfig = {
    apiKey: "AIzaSyCFyY5iSORy-M9R2Nd8VNjy-EDSjSQZOYk",
    authDomain: "firesell-85835.firebaseapp.com",
    databaseURL: "https://firesell-85835.firebaseio.com",
    projectId: "firesell-85835",
    storageBucket: "firesell-85835.appspot.com",
    messagingSenderId: "190304961477",
    appId: "1:190304961477:web:543a287b7a83af004101ac",
    measurementId: "G-55T0F190FE"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export const customerService = {
    addNewEarlyAdopter,
    addNewEmailSubscription,
    addNewContactEnquiry
};

function addNewEarlyAdopter(customer) {
    return new Promise((resolve, reject) => {
        const docRef = db.collection('customers');
        docRef.add(customer)
            .then(() => {
                sendEarlyAdopterConfirmationEmail(customer.email)
                resolve();
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
                reject();
            });
    });
}

function addNewEmailSubscription(customer) {
    return new Promise((resolve, reject) => {
        const docRef = db.collection('newsletter-list');
        docRef.add(customer)
            .then(() => {
                sendMailingListConfirmationEmail(customer.email);
                resolve();
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
                reject();
            });
    });
}

function addNewContactEnquiry(enquiry) {
    return new Promise((resolve, reject) => {
        const docRef = db.collection('contact-enquiry');
        docRef.add(enquiry)
            .then(() => {
                sendContactEnquiryConfirmationEmail(enquiry);
                resolve();
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
                reject();
            });
    });
}

function sendMailingListConfirmationEmail(email) {
    if (email == "") {
        return;
    }
    axios({
        method: 'post',
        url: `https://marketsheets.herokuapp.com/mailing-list-firesell`,
        data: {
            email: email
        }
    })
    axios({
        method: 'post',
        url: `https://hook.notify17.net/api/raw/Z1z7ifyw9vRtEiiiiLDnCJ97JPvn4ZCFIAyNLGEaiWc`,
        data: {
            title: "New user added to mailing list.",
            content: email
        }
    })
}

function sendEarlyAdopterConfirmationEmail(email) {
    if (email == "") {
        return;
    }
    axios({
        method: 'post',
        url: `https://marketsheets.herokuapp.com/mailing-list-firesell/send-early-adopter-email`,
        data: {
            email: email
        }
    })
    axios({
        method: 'post',
        url: `https://hook.notify17.net/api/raw/Z1z7ifyw9vRtEiiiiLDnCJ97JPvn4ZCFIAyNLGEaiWc`,
        data: {
            title: "New user sign up",
            content: email
        }
    })
}

function sendContactEnquiryConfirmationEmail(enquiry) {
    if (email == "") {
        return;
    }
    axios({
        method: 'post',
        url: `https://marketsheets.herokuapp.com/mailing-list-firesell/send-contact-email`,
        data: enquiry
    })
    axios({
        method: 'post',
        url: `https://hook.notify17.net/api/raw/Z1z7ifyw9vRtEiiiiLDnCJ97JPvn4ZCFIAyNLGEaiWc`,
        data: {
            title: "New contact inquiry",
            content: email
        }
    })
}