import React, { Component } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';



class AccordionDemo extends Component {

    render() {
        return (
            <Accordion>
                <div className="mb-3">
                    <h3>FAQs</h3>
                </div>
                <AccordionItem className="accordion__item mb-2" expanded >
                    <AccordionItemTitle className="border mb-0 bg-transparent card-header">
                        <h6 className="mb-0">
                            <a className="text-dark" data-toggle="collapse" data-parent="#accordion" href="#collapse1" aria-expanded="true">What are the benefits for the merchant?</a>
                        </h6>
                    </AccordionItemTitle>
                    <AccordionItemBody>
                        <div className="text-muted">Firesell’s early adopter program is an opportunity to boost the sales of your slow moving products. Once installed onto your ecommerce store, Firesell runs in the background to sell your inventory for you. Your feedback will help shape our product to better cater to your pain points.</div>
                    </AccordionItemBody>
                </AccordionItem>

                <AccordionItem className="accordion__item mb-2">
                    <AccordionItemTitle className="border mb-0 bg-transparent card-header">
                        <h6 className="mb-0">
                            <a className="text-dark" data-toggle="collapse" data-parent="#accordion" href="#collapse2">Who is an ideal merchant for the program?</a>
                        </h6>
                    </AccordionItemTitle>
                    <AccordionItemBody>
                        <div className="text-muted">We are looking for merchants that sell consumer goods and keep physical inventory (not dropshipping). Ideally the merchant already has slow moving inventory and/or dead stock to begin Fireselling from day one. </div>
                    </AccordionItemBody>
                </AccordionItem>

                <AccordionItem className="accordion__item mb-2">
                    <AccordionItemTitle className="border mb-0 bg-transparent card-header">
                        <h6 className="mb-0">
                            <a className="text-dark" data-toggle="collapse" data-parent="#accordion" href="#collapse2">What is the commitment for the merchant?</a>
                        </h6>
                    </AccordionItemTitle>
                    <AccordionItemBody>
                        <div className="text-muted">The commitment is minimal. After the Firesell app has been installed on your store we will work with you to understand your inventory problem. We typically meet with our merchants every 2-3 weeks to get feedback and assess performance. </div>
                    </AccordionItemBody>
                </AccordionItem>

                <AccordionItem className="accordion__item mb-2">
                    <AccordionItemTitle className="border mb-0 bg-transparent card-header">
                        <h6 className="mb-0">
                            <a className="text-dark" data-toggle="collapse" data-parent="#accordion" href="#collapse3">What if I use a custom ecommerce platform?</a>
                        </h6>
                    </AccordionItemTitle>
                    <AccordionItemBody>
                        <div className="text-muted">Not a problem. We are always open to building custom integrations depending on the scale of our customer's problem.</div>
                    </AccordionItemBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemTitle className="border mb-0 bg-transparent card-header">
                        <h6 className="mb-0">
                            <a className="text-dark" data-toggle="collapse" data-parent="#accordion" href="#collapse3">Can I choose which sales channels my products sell on?</a>
                        </h6>
                    </AccordionItemTitle>
                    <AccordionItemBody>
                        <div className="text-muted">Yes. We use the product category, sales history and market trends to automatically determine which sales channels would be best suited to liquidate. However, we always provide the merchant with the option to opt-out of certain sales channels if they do not want their products to appear there.</div>
                    </AccordionItemBody>
                </AccordionItem>
            </Accordion>
        );
    }
}

export default AccordionDemo;